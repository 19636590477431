::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9; 
}
 
::-webkit-scrollbar-thumb {
  background: #1b1617; 
}

::-webkit-scrollbar-thumb:hover {
  background: #1b1617; 
}

.bg-light-grey {
  background: $bg-light-grey;
}

.bg-gray-4{
  background-color: #d4d4d4;
}

.bg-shaded-light {
    background-color: hsla(0,0%,92.5%,.493);
}

// .bg-light-yellow {
//   background: $bg-light-yellow;
// }
.product-gallery {
  border: solid 1px $border-light-grey;
}
.theme-color {
  // background-color: #012c40;
  // background-color: #d0d1d3;
  background-color: #f3f3f3;
}
.text-white h2 {
  color: $white !important;
}
.text-white h2::before {
  background-color: $white !important;
}
.text-white h2::after {
  background-color: $white !important;
}

.product-content {
  &.title-price-wrap-2-white {
    h3 {
      a {
        color: #fff;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .product-price {
      span {
        color: #b3b3b3;

        &::before {
          background-color: #b3b3b3;
        }

        &.old {
          text-decoration: line-through;
          color: #797979;
        }
      }
    }
  }
}

// ------------ Top Bar -----------------
.header-offer p {
  line-height: 54px;
}
// --------------------------------------

// ----------- About Us Page ------------
.welcome-content p {
  width: 100%;
  text-align: left;
}
// --------------------------------------

// -------------- Header ----------------
.logo {
  height: 100%;
  // margin-top: 0px;

  & > a {
    display: block;
    height: 100%;
    text-align: center;

    img {
      //   margin-top: 14px;
      width: 100%;
    }
  }
}
.stick {
  & .logo > a > img {
    margin-top: 0px;
    width: 70%;
  }
}
// -----------------------------------------

// ------------- Instagram Wall ------------
.Feed img {
  width: 12.5%;
}
.Feed {
  padding-top: 50px;
}
// -----------------------------------------

// ----------------- Footer ----------------
.footer-logo {
  img {
    width: 100%;
  }
}
// -----------------------------------------

// --------- Listing page filters ----------
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
  top: 5px;
}
// -----------------------------------------

// ------------ Product Gallery ------------
.product-large-image-wrapper .react_lightgallery_item button {
  position: absolute;
}
//

.btn-secondary p {
  color: #fff !important;
}
.billing-info-wrap .btn-group {
  display: inherit !important;
}
// -----------------------------------------

// --------- Products Details Page ---------
.product-details-content
  .pro-details-size-color
  .pro-details-color-wrap
  .pro-details-color-content--single
  .checkmark:after {
  left: -3px;
  top: -6px;
}
// -----------------------------------------

// ----- Checkout Address Card Checkboxes -----
.address-card-lable {
  width: 100%;
}

.card-input-element {
  display: none;
}

.card-input {
  margin: 10px;
  padding: 00px;
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #2ecc71;
}
#add-address-card {
  text-align: center;
}
// -----------------------------------------------

input#checkout-different-address {
  width: 14%;
  float: left;
  margin-right: 10px;
}

// ------------- Menu START -----------------
.main-menu nav ul li:hover a {
  // color: #fff;
  color: #5f5e5f;
}
// _______Bikes Categories______
.bike-categories-menu-img-container {
  width: 300px;
  height: 258px;
  overflow: hidden;
}
.mega-menu-img img {
  width: 100%;
}
ul.bike-categories-menu {
  width: 540px !important;
}
.bike-categories-menu > li:nth-child(1) {
  width: 35% !important;
}
.bike-categories-menu > li:nth-child(2) {
  width: 65% !important;
}
// _____Bikes Categories END_____

// _____Spares & Accessories_____
.sub-menu-accessories {
  min-width: 436px;
  li {
    width: 50%;
    display: inline-block !important;
    float: left !important;
  }
}
.main-menu nav ul li ul.mega-menu.spares-accessories-megamenu {
  width: 753px;
  left: -156%;
}
.spares-column {
  width: 40% !important;
}
.accessories-column {
  width: 60% !important;
}
// _____Spares & Accessories END_____

// _____Shop By Brands_____
.shop-by-brands-sub-menu {
  min-width: 330px;
  li {
    width: 50%;
    display: inline-block !important;
    float: left !important;
  }
}
.shop-by-brands {
  width: 100% !important;
}
.shop-by-brands-mega-menu {
  width: auto !important;
}
// _____Shop By Brands END_____
// ------------------------------------------------------

.your-order-total {
  width: 100%;
}

.welcome-area.pt-100.pb-95 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.welcome-content p {
  font-size: 15px;
  color: #4b4b4b;
  line-height: 30px;
  width: 100%;
  margin: 0 auto;
}
.contact-info-wrap {
  // padding: 120px 10px 116px 10px;
  background-color: #f3f3f3;
}

@media only screen and (min-width: 1245px) and (max-width: 1395px) {
  .main-menu nav ul li {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 1161px) and (max-width: 1244px) {
  .main-menu nav ul li {
    padding: 0 10px;
  }
  .logo {
    height: 100% !important;
    // margin-top: 28px !important;
  }
  .main-menu nav ul li > a {
    font-size: 16px;
    font-weight: 500;
    line-height: 74px !important;
    display: inline-block;
    letter-spacing: 0.8px;
    // color: white;
    color: #5f5e5f;
  }
  .header-right-wrap .same-style > a,
  .header-right-wrap .same-style button {
    font-size: 18px;
    color: #000;
  }
  .header-right-wrap .same-style.account-setting > a,
  .header-right-wrap .same-style.account-setting button {
    font-size: 20px;
  }
  .header-right-wrap .same-style.cart-wrap button,
  .header-right-wrap .same-style.cart-wrap > a,
  .header-right-wrap .same-style.header-wishlist button,
  .header-right-wrap .same-style.header-wishlist > a,
  .header-right-wrap .same-style.header-compare button,
  .header-right-wrap .same-style.header-compare > a {
    font-size: 20px;
    position: relative;
    display: inline-block;
    padding: 0;
    color: #000;
    border: none;
    background: transparent;
  }
}
@media only screen and (min-width: 1070px) and (max-width: 1160px) {
  .main-menu nav ul li {
    padding: 0 10px;
  }
  .logo {
    height: 100% !important;
    // margin-top: 28px !important;
  }
  .main-menu nav ul li > a {
    font-size: 12px;
    font-weight: 500;
    line-height: 74px !important;
    display: inline-block;
    letter-spacing: 0.8px;
    // color: white;
    color: #5f5e5f;
  }
  .header-right-wrap .same-style > a,
  .header-right-wrap .same-style button {
    font-size: 18px;
    color: #000;
  }
  .header-right-wrap .same-style.account-setting > a,
  .header-right-wrap .same-style.account-setting button {
    font-size: 20px;
  }
  .header-right-wrap .same-style.cart-wrap button,
  .header-right-wrap .same-style.cart-wrap > a,
  .header-right-wrap .same-style.header-wishlist button,
  .header-right-wrap .same-style.header-wishlist > a,
  .header-right-wrap .same-style.header-compare button,
  .header-right-wrap .same-style.header-compare > a {
    font-size: 20px;
    position: relative;
    display: inline-block;
    padding: 0;
    color: #000;
    border: none;
    background: transparent;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1069px) {
  .main-menu nav ul li {
    padding: 0 10px;
  }
  .logo {
    height: 100% !important;
    // margin-top: 28px !important;
  }
  .main-menu nav ul li > a {
    font-size: 11px;
    font-weight: 500;
    line-height: 74px !important;
    display: inline-block;
    letter-spacing: 0.8px;
    // color: white;
    color: #5f5e5f;
  }
  .header-right-wrap .same-style > a,
  .header-right-wrap .same-style button {
    font-size: 18px;
    color: #000;
  }
  .header-right-wrap .same-style.account-setting > a,
  .header-right-wrap .same-style.account-setting button {
    font-size: 18px;
  }
  .header-right-wrap .same-style.cart-wrap button,
  .header-right-wrap .same-style.cart-wrap > a,
  .header-right-wrap .same-style.header-wishlist button,
  .header-right-wrap .same-style.header-wishlist > a,
  .header-right-wrap .same-style.header-compare button,
  .header-right-wrap .same-style.header-compare > a {
    font-size: 18px;
    position: relative;
    display: inline-block;
    padding: 0;
    color: #000;
    border: none;
    background: transparent;
  }
  .header-right-wrap .same-style {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right-wrap {
    // margin-top: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-1 .container-fluid,
  .header-padding-1 .container-sm,
  .header-padding-1 .container-md,
  .header-padding-1 .container-lg,
  .header-padding-1 .container-xl {
    padding: 0 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-1 .container-fluid,
  .header-padding-1 .container-sm,
  .header-padding-1 .container-md,
  .header-padding-1 .container-lg,
  .header-padding-1 .container-xl {
    padding: 0 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .header-right-wrap {
    margin-top: 15px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .header-res-padding {
    padding: 0px 0 0px;
  }
  .bg-light-yellow {
    padding: 0 0px 0 0px;
  }
  .logo > a {
    display: block;
    height: 100%;
    text-align: center;
    line-height: 44px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
  .footer-widget.ml-30,
  .footer-widget.ml-50,
  .footer-widget.ml-75 {
    margin-left: 0;
    text-align: center;
  }

  .copyright.mb-30 p {
    text-align: center;
  }
  .footer-widget.ml-70 {
    margin-left: 0;
    text-align: center;
  }
  .copyright .footer-logo {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .stick .logo,
  .stick .header-right-wrap {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-1 {
    padding: 0 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: auto;
    padding: 0px 0 0;
  }
  .brand-logo {
    margin-bottom: 10px;
  }
  .banner-area .container-fluid {
    padding: 0px 15px !important;
  }
  .single-banner {
    margin: 0px !important;
  }
  .single-banner a img {
    margin-bottom: 15px;
  }
  .featuretitle {
    font-size: 25px;
  }
  .footer-logo img {
    width: auto;
  }
  .footer-logo {
    text-align: center;
  }
}
.single-slider .container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding: 0px;
}
.static-container h6 {
  text-align: center;
}
.contact-info-wrap .single-contact-info .contact-icon {
  margin-right: 7px !important;
}

@media only screen and (max-width: 998px) {
  .contact-info-wrap .single-contact-info .contact-info-dec p {
    font-size: 13px;
  }
}

h2.pop {
  font-size: 11px !important;
  line-height: 20px !important;
}

.pop::before,
.pop::after {
  top: 9px !important;
}

.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: rgb(255, 6, 6);
  }
  49% {
    color: rgb(248, 17, 17);
  }
  60% {
    color: #212529;
  }
  99% {
    color: #212529;
  }
  100% {
    color: rgb(247, 17, 17);
  }
}
.section-title.text-center.sxs {
  background-color: #fdfdfd;
}

// .main-menu nav li:hover {
//   background-color: #928c8c61;
// }
ul.submenu li:hover {
  background-color: white;
}

ul.mega-menu.mega-menu-padding.shop-by-brands-mega-menu li:hover {
  background-color: #ffffff;
}

ul.mega-menu.bike-categories-menu li:hover {
  background-color: #ffffff;
}

ul.mega-menu.mega-menu-padding.spares-accessories-megamenu li:hover {
  background-color: white;
}

.footer-widget .footer-list ul li a:hover {
  color: #fff !important;
}

.copyright p a:hover {
  color: #fff !important;
  font-weight: 400;
}

.footer-widget .subscribe-style .subscribe-form .clear button {
  font-size: 14px;
  line-height: 1;
  width: auto;
  height: auto;
  margin: 13px 0 0;
  padding: 0 0 1px;
  text-transform: uppercase;
  color: #040303;
  border: none;
  background: #fff;
  padding: 8px;
  font-weight: 700;
}

.product-tab-list {
  justify-content: center;
}

.header-hm9 .main-menu nav ul li a {
  color: #5f5e5f;
}

.main-menu nav ul li ul.mega-menu > li ul li a:hover {
  color: #5f5e5f;
}

.welcome-content p {
  font-size: 18px;
  color: #433f3f;
  line-height: 30px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-family: $rc;
}

.product-wrap-2 .product-content-2 {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
  text-align: center;
}

.custom2-col-4 {
  position: relative;
  flex: 0 0 25%;
  width: 100%;
  max-width: 25%;
  min-height: 1px;
}

.product-wrap-2 {
  position: relative;
  overflow: hidden;
  padding: 0 0 40px 0;
  border-bottom: 3px solid #fff;
}

.product-wrap-2:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  transition: 0.3s ease-in-out;
  transform: translateY(-5px);
  border-bottom: 3px solid #00afef;
}

.home1footer {
  background-color: #192330;
}

.footer-widget .subscribe-style .subscribe-form .clear button:hover {
  background-color: #fff;
  color: #000;
  border-bottom: none;
  transform: translateY(2px);
}

.nice-select {
  border-radius: 0;
  color: #65696c;
  max-width: 100%;
}

.nice-select .current {
  font-size: 18px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
}

.nice-select:hover {
  border-color: #00afef;
}

.nice-select .list {
  border-radius: 0;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #00afef;
}

.nice-select .option.disabled {
  color: #535b65;
}


.s-find-bike {
  margin-top: 0px;
  position: relative;
  z-index: 3;
}

.s-find-bike .title {
  margin-bottom: 29px;
  text-align: center;
}

.s-find-bike .title-cover {
  text-align: center;
}

@media (max-width: 1366px) {
  .s-find-bike {
      margin-top: -48px;
  }
}

@media (max-width: 1199px) {
  .s-find-bike {
      margin-top: -35px;
  }
  .find-bike-form {
      padding: 44px 30px 48px;
  }
}

@media (max-width: 991px) {
  .s-find-bike .nice-select {
      padding-left: 15px;
      padding-right: 24px;
  }
  .s-find-bike .nice-select:after {
      right: 10px;
  }
  .find-bike-form .form-wrap>li .btn {
      min-width: 150px;
  }
  .find-bike-form .form-wrap>li .nice-select {
      max-width: 146px;
  }
  .nice-select .option {
      padding-left: 15px;
      padding-right: 10px;
      font-size: 12px;
      line-height: 36px;
      min-height: 36px;
  }
}

@media (max-width: 767px) {
  .s-find-bike .find-bike-form .form-wrap>li {
      margin-right: 0;
      margin-bottom: 20px;
      width: calc(50% - 15px);
  }
  .s-find-bike {
      margin-top: 34px;
  }
  .s-find-bike .title {
      margin-bottom: 24px;
  }
  .find-bike-form .form-wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
  }
  .find-bike-form {
      padding: 39px 30px 20px;
  }
  .find-bike-form .form-wrap>li .nice-select {
      max-width: 100%;
  }
}

@media (max-width: 575px) {
  .s-find-bike .find-bike-form .form-wrap>li {
      width: 100%;
  }
  .find-bike-form .form-wrap>li:last-child {
      margin-top: 10px;
  }.nice-select .option {
      padding: 0 10px;
  }
}

.find-bike-form {
  padding: 54px 57px 58px;
  // background-color: #fff;
  // -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.find-bike-form .form-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.find-bike-form .form-wrap label {
  display: block;
  color: #9ca4ab;
}

.find-bike-form .form-wrap>li {
  margin-right: 30px;
  width: calc(33.33% - 15px);
}

.find-bike-form .form-wrap>li:last-child {
  margin-right: 0;
  width: auto;
}

.find-bike-form .form-wrap>li .nice-select {
  width: 100%;
  max-width: 100%;
  border: 1px solid #1b16172e;
  padding: 10px;
  border-radius: 4px;
}

.find-bike-form .form-wrap>li .btn {
  display: block;
  min-width: 195px;
  padding: 9px;
  background-color: #00afef;
  color: #fff;
}


.nav-style-3.owl-carousel > .owl-nav, .nav-style-3 .ht-swiper-button-nav{
  top: 50%;
  right: 15px;
  font-size: 35px;
  font-weight: 800;
  line-height: 1.5;
  height: 50px;
  width: 50px;
  border: 1px solid #231f20;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-menu nav ul li ul.submenu{
  margin-top: -10px;
  box-shadow: none;
}

.main-menu nav ul li ul.submenu li a{
  font-size: 15px;
  font-family: $r;
  font-weight: 600;
  margin: 8px 0;
}

.main-menu nav ul li>a {
  color: #231f20;
}

.logo>a img {
  width: auto;
}

.stick .logo {
  margin-top: 17px !important;
}

.logo {
  // margin-top: 27px;
}

.welcome-content h1:before{
  background-color: #00afef;
  content: none;
}

.product-tab-list a.active h4 {
  color: #00afef;
  font-weight: 600;
  border-bottom: 2px dotted #00afef;
}

.product-wrap-2.pro-glaucous-color .product-img .product-action-2 a, .product-wrap-2.pro-glaucous-color .product-img .product-action-2 button{
  background-color: #00afef;
}

.scroll-top{
  background-color: #00afef;
}

.welcome-content h1{
  font-family: $teko;
  font-size: 50px;
  font-weight: 600;
  margin: 8px 0 0px;
  text-transform: uppercase;
}

.find-bike-form .form-wrap label {
  display: block;
  color: #433f3f;
}

.header-right-wrap .same-style.header-search .search-content form .button-search{
  background: #c6c6c6;
}

.header-right-wrap .same-style.header-search .search-content form .button-search:hover{
  background: #c6c6c6;
}

.support-wrap-2 .support-content-2 h5{
  font-family: $timmana;
}

.product-wrap-2 {
  background-color: #fff;
}

.footer-logo img {
  width: auto;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a{
  font-size: 18px;
  font-family: $rc;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 h2 a{
  font-size: 16px;
  font-weight: 600;
}

.contact-info-wrap {
    padding: 50px;
}

.contact-form .contact-title h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
    color: #464646;
}

.contact-info-wrap .contact-title h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #464646;
}

.contact-info-wrap .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}

.contact-social ul li {
    display: inline-block;
    margin: 10px 20px 0 20px;
}

.contact-info-wrap .single-contact-info:hover .contact-icon i {
    color: #fff;
    background-color: #00afef;
    border-color: #00afef;
    border: 1px solid #00afef;
}

.contact-form .contact-form-style button {
    padding: 15px 35px;
    border: 1px solid #777;
    background-color: transparent;
    color: #777;
    margin-top: 15px;
    font-weight: 400;
}
.contact-form .contact-form-style button:hover{
      background-color: #00afef;
      color: #fff;
}

.contact-social {
    margin-top: 40px;
}

.contact-social ul li a:hover{
    color: #00afef
}

.f_size_16 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

footer h5{
  color: rgb(216, 216, 216);
  line-height: 22px;
  font-family: $rc;
  font-size: 16px;
}

footer a{
  color: rgb(216, 216, 216);
}

footer a:hover{
  color: #00afef;
}

footer ul.b_cat li, footer ul.com_links li{
  line-height: 18px;
}

footer ul.b_cat li a, footer ul.com_links li a {
    font-size: 14px;
    font-weight: 400;
    color: rgb(216, 216, 216);
    text-transform: uppercase;
}

.f_social_icon a {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: #fff;
    border: 1px solid #e2e2eb;
    font-size: 20px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #444;
    margin-left: 4px;
}

footer hr{
  border: .02em solid rgba(255, 255, 255, 0.11);
}


.product-wrap .product-img, .product-wrap .product-img img{
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


@media only screen and (min-width: 768px) and (max-width: 991px){
.logo {
    margin-top: 0 !important;
}
}

@media only screen and (max-width: 767px)
{
.logo {
    margin-top: 2px !important;
}}

@media only screen and (min-width: 268px) and (max-width: 990px){
  .stick .logo {
    margin-top: 0px !important;
  }
}

.main-menu nav ul li > a {
  line-height: 74px;
}


.logo > a {
    display: block;
    height: 100%;
    margin-left: 10px;
    text-align: left !important;
}

.description-review-topbar {
    justify-content: left;
    border-bottom: none;
    display: block;
}
.description-review-topbar .nav-item a {
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.description-review-topbar .nav-item a.active {
    border-bottom: none;
    background-color: #00aff0;
    width: 100%;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    line-height: 52px;
    letter-spacing: 1px;
}
.tab-content .active {
    display: block;
    height: auto;
    overflow: visible;
}
.description-review-bottom {
    padding: 15px 0 0;
    background-color: #f7f7f7;
}

@media only screen and (min-width: 280px) and (max-width: 1115px){
  .description-review-bottom ul {
    column-count: 1 !important;
  }
}
.description-review-bottom ul {
    padding: 0px 30px 30px 30px;
    column-count: 2;
    background-color: #f7f7f7;
}

.description-review-bottom .product-anotherinfo-wrapper ul table {
    margin: 0px 10px 0px 0;
    line-height: 30px;
}

.description-review-bottom .product-anotherinfo-wrapper ul table tr td:nth-child(1) {
    min-width: 110px;
    font-weight: 800;
    color: #131313;
    font-family: $alg;
    font-size: 15px;
    vertical-align: top;
}

.description-review-bottom .product-anotherinfo-wrapper ul table tr :nth-child(2) {
    font-weight: 600;
    color: #434343;
    font-family: $alg;
    font-size: 15px;
    vertical-align: top;
    margin-right: 3px;
}

.description-review-bottom .product-anotherinfo-wrapper ul table tr td:nth-child(3) {
    font-weight: 600;
    color: #434343;
    font-family: $alg;
    font-size: 15px;
    vertical-align: top;
}

.description-review-bottom .product-anotherinfo-wrapper ul li span {
    min-width: 80px;
    font-weight: 800;
    color: #131313;
}
.description-review-bottom .product-anotherinfo-wrapper ul li {
    margin: 0;
    padding: 10px 0px 10px 30px;
    font-size: 15px;
    font-family: $alg;
    color: #434343;
    font-weight: 600;
}

// .description-review-bottom .product-anotherinfo-wrapper ul li:nth-child(odd) {
//     background-color: #e9e9e9;
//     padding: 8px 8px 8px 10px;
//     color: #555;
// }
// .description-review-bottom .product-anotherinfo-wrapper ul li:nth-child(2n) {
//     background-color: #fff;
//     padding: 8px 8px 8px 10px;
//     color: #555;
// }

.main-menu nav ul{
    text-align: center;
}

.header-area {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}


.product-details-content .pro-details-meta span{
  font-size: 18px;
  margin-right: 5px;
  color: #8d8d8d;
  font-weight: 600;
}

.product-details-content .pro-details-meta ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #8d8d8d;
}

.product-details-content .pro-details-size-color .pro-details-size span{
  font-size: 15px;
  margin-right: 5px;
  color: #333;
  font-weight: 600;
  letter-spacing: 0.5px;
}

// amazon button

.amazon-btn{
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  background-image: linear-gradient(#ffd814, #ffa41c);
  font-weight: 500;
  text-transform: uppercase;
  color: #273039;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid #273039;
  border-radius: 5px;
}

.amazon-btn i{
  font-size: 20px;
}

.amazon-btn:hover{
  color: #273039;
  transform: scale(1.03);
}

.single-testimonial p{
  margin: 20px 0 0;
  font-family: $rc;
  font-size: 18px;
}

.single-testimonial img {
  border-radius: 15px !important;
}

.stick .logo > a > img {
    margin-top: 2px;
    width: 100%; 
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single{
  // width: 100%;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single {
  // display: inline;
  padding: 0;
  margin: 8px 5px;
  border: 1px solid #e7e7e7;
  background-color: #e7e7e7;
  width: 75px;
  text-transform: uppercase;
  text-align: center;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name {
  font-size: 15px;
  font-weight: 600;
  padding: 12px;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name:hover {
  color: #fff;
}

.pro-details-color-wrap {
  display: block;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
  padding: 15px 0 0px 0;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single:hover {
  background-color: #707070;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.product-details-content .pro-details-size-color {
    display: block;
}


img {
    max-width: 100%;
}
.product-gallery img {
    height: auto!important;
}

.js-image-zoom__zoomed-image {
    z-index: 1;
}

.product-gallery {
    border: 1px solid #fff;
}


.product-details-content .pro-details-list {
    margin: 10px 0 0;
    padding: 0 0 10px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap {
    padding: 15px 0 0;
    margin-right: 0;
}


.pro-details-color-content {
    margin: 0 10px;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap .pro-details-color-content--single .checkmark:after {
    left: -3px;
    top: -5px;
    width: 31px;
    height: 31px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
}

.product-details-content .pro-details-size{
      padding: 15px 0;
    margin-right: 0;
}

.product-details-content .pro-details-meta {
    padding: 0;
    margin-right: 0;
}

.product-details-content .pro-details-size-color .pro-details-color-wrap span{
    font-size: 15px;
    margin-right: 5px;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.product-details-content .pro-details-meta span{
  text-transform: uppercase;
}

.product-details-content h2{
  color: #231f20;
  font-weight: 600;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name{
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35); 
    color: #fff;
    background-color: #e7e7e7;
    color: #5e5b5c;
    border: 1px solid #000;
    font-size: 16px;
    font-weight: 600;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:hover {
    color: #fff;
    background-color: #e7e7e7;
    border: 1px solid #000;
}

.product-details-content
  .pro-details-size-color
  .pro-details-size
  .pro-details-size-content--single
  .size-name:hover {
  color: #fff;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover{
    background-color: #e7e7e7;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #5e5b5c;
    border: 1px solid #000;
}



.s-our-advantages .mask {
    background-color: #192330;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.96;
    width: 100%;
    z-index: 1;
    height: 100%;
    pointer-events: none;
}

.s-our-advantages {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    color: #fff;
    padding: 116px 0 143px;
}
.s-our-advantages:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: #fff;
    z-index: 2;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 0);
}
.s-our-advantages:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fafafa;
    height: 44px;
    z-index: 2;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 25% 100%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 25% 100%);
}
.s-our-advantages .container {
    position: relative;
    z-index: 1;
}

@media (max-width: 1199px) {
  .s-our-advantages {
        padding: 110px 0;
    }
.s-our-advantages {
        padding: 110px 0 115px;
    }
  .s-our-advantages:before {
        height: 25px;
        top: -1px;
    }
    .s-our-advantages:after {
        height: 34px;
    }
     .s-our-advantages:after {
        bottom: -1px;
    }
}
@media (max-width: 767px) {
      .s-our-advantages {
        padding: 100px 0 70px;
    }
    .s-our-advantages:after, .s-our-advantages:before{
      height: 20px;
    }
    .s-our-advantages:after {
        bottom: -1px;
    }
}
.our-advantages-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.our-advantages-wrap.advantages-wrap-about {
    text-align: center;
    margin-top: 150px;
}
.our-advantages-wrap.advantages-wrap-about .our-advantages-item h5 {
    color: #192330;
}
.our-advantages-item {
    width: calc(25% - 22px);
}
.our-advantages-item h5 {
    color: #fff;
    margin: 23px auto 0;
    font-style: normal;
    font-size: 30px;
    text-transform: none;
    font-weight: 600;
    font-family: $teko;
    line-height: 1.1;
}

@media (max-width: 1199px) {
  .our-advantages-wrap.advantages-wrap-about {
        margin-top: 120px;
    }
    .our-advantages-wrap {
        margin-top: 62px;
    }
.our-advantages-item h5 {
        margin-top: 20px;
        font-size: 24px;
    }
    .our-advantages-item img {
        max-height: 52px;
    }

}
@media (max-width: 991px) {

      .our-advantages-item {
        width: calc(25% - 4px);
    }
    .our-advantages-item h5 {
        font-size: 22px;
    }
}

@media (max-width: 767px) {
  .our-advantages-wrap.advantages-wrap-about {
        margin-top: 100px;
    }
  .our-advantages-wrap {
        margin-top: 45px;
    }
  .our-advantages-item {
        width: calc(25% - 15px);
        margin-bottom: 30px;
    }
    .our-advantages-item img {
        max-height: 40px;
    }
    .our-advantages-item h5 {
        font-size: 20px;
        margin-top: 12px;
        font-weight: 400;
    }
}
@media (max-width: 575px) {
  .our-advantages-item {
        width: calc(50% - 10px);
        margin-bottom: 40px;
    }
}




.s-banner .mask {
    background-color: #192330;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.96;
    width: 100%;
    z-index: 1;
    height: 100%;
    pointer-events: none;
}

.s-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 100px 0 100px;
}
.s-banner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: #fafafa;
    z-index: 3;
    -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
    clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.s-banner .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.s-banner .banner-img .banner-img-bg {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.s-banner .banner-img img {
    max-height: 100%;
    max-width: 40%;
    position: relative;
    z-index: 2;
}
.s-banner .container {
    position: relative;
    z-index: 4;
    text-align: center;
    color: #fff;
}
.s-banner .container .title {
    color: #fff;
    margin-bottom: 19px;
}
.s-banner .slogan {
    max-width: 500px;
    margin: 0 auto 42px;
    font-size: 18px;
    color: #fff;
    font-family: $rc;
}
.s-banner.s-dark-banner {
    padding: 89px 0 100px;
}
.s-banner.s-dark-banner:before {
    background-color: #161616;
}
.s-banner.s-dark-banner .mask {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    opacity: 1;
}
.s-banner.s-dark-banner .banner-img .banner-img-bg {
    opacity: 0.2;
    mix-blend-mode: overlay;
}
.s-banner.s-dark-banner .banner-price .new-price {
    color: #f57212;
}
.s-banner.s-dark-banner #clockdiv {
    background: rgba(255, 255, 255, 0.05);
}
.s-banner.s-dark-banner #clockdiv .smalltext {
    color: #23c050;
}

@media (max-width: 1366px) {
  .s-banner {
        padding: 100px 0 110px;
    }
  .s-banner:before {
        top: -1px;
    }
    
}

@media (max-width: 1199px) {
.s-banner {
        padding: 118px 0 105px;
    }
  .s-banner:before {
        top: -1px;
    }
  .s-banner .slogan {
        max-width: 370px;
        margin-bottom: 34px;
    }
}

@media (max-width: 767px) {
    .s-banner {
        padding: 100px 0 90px;
    }
     .s-banner:before {
        height: 20px;
    }
}

#clockdiv {
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: center;
    font-size: 50px;
    line-height: 0.85em;
    background-color: #474f59;
    -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    font-weight: 600;
    font-family: "Teko", sans-serif;
    padding: 20px 0 12px;
}
#clockdiv > div {
    padding: 10px;
    min-width: 131px;
    position: relative;
}
#clockdiv > div:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: rgba(235, 237, 236, 0.2);
    width: 1px;
    height: 78%;
}
#clockdiv > div:last-child:before {
    display: none;
}
#clockdiv > div > span {
    display: inline-block;
    margin-bottom: 13px;
}
#clockdiv .smalltext {
    padding-top: 0;
    margin-top: -3px;
    font-size: 24px;
    line-height: 0.85em;
    color: #00afef;
    text-transform: uppercase;
}

.banner-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Teko", sans-serif;
    letter-spacing: 0;
    margin-bottom: 59px;
}
.banner-price .new-price {
    line-height: 85%;
    font-size: 50px;
    color: #00afef;
    margin-right: 15px;
}
.banner-price .old-price {
    color: #9ca4ab;
    font-size: 36px;
    line-height: 85%;
    text-decoration: line-through;
    position: relative;
}

@media (max-width: 1199px) {
  #clockdiv > div {
        min-width: 106px;
    }
    #clockdiv {
        font-size: 40px;
    }
    #clockdiv .smalltext {
        font-size: 18px;
        margin-top: 0;
    }
}

@media (max-width: 991px) {
  #clockdiv > div > span {
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 767px) {
  #clockdiv > div {
        min-width: 110px;
    }
}

@media (max-width: 575px) {
  #clockdiv {
        font-size: 36px;
    }
    #clockdiv > div {
        min-width: 72px;
    }
    #clockdiv .smalltext {
        font-size: 14px;
    }
}


// footer:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 24px;
//     background-color: #fafafa;
//     z-index: 3;
//     -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
//     clip-path: polygon(0 0, 100% 100%, 100% 0);
// }

.main-menu nav ul li > a{
  font-size: 15px;
  font-family: $r;
  font-weight: 600;
  color: #fff;
}

.main-menu nav ul li > a:hover{
  color: #fff;
}

.main-menu nav ul li > a i{
  font-size: 17px;
}
.header-padding-1 .container-fluid{
  padding: 15px 50px;
  background-color: #474f59;
}

.language-currency-wrap .same-language-currency p{
  font-family: $rc;
  font-size: 15px;
  color: #fff;
}

.slider-height-5{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

#call-us-btn{
  background-color: #00afef;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#mail-us-btn{
  background-color: #45484f;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.main-menu nav ul li ul.submenu li a::before{
    position: absolute;
    z-index: 999;
    top: 13px;
    left: 15px;
    width: 7px;
    height: 7px;
    // content: "";
    // transition: all 0.3s ease-in-out 0s;
    opacity: 0;
    border-radius: 50%;
    // background: #1b1617 none repeat scroll 0 0;
}


@media only screen and (min-width: 228px) and (max-width: 768px){
  #call-us-btn{
  display: none;
}

#mail-us-btn{
  display: none;
}
}

.new-badge {
  padding: 4px 13px;
  color: #fff;
  font-size: 12px;
  border-radius: 0px 20px 0 0 ;
}

.home-about p{
  font-family: $alg;
  font-weight: 500;
  font-size: 21px;
  color: #45494d;
  line-height: 1.6;
}

.home-about p strong{
  font-family: $teko;
  font-weight: 700;
  font-size: 22px;
  color: #45494d;
  line-height: 1.5;
}







// ========================================
/* single products  */
.product-item {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-item:hover .actions-btn{
  background-color: #fff;
  transition: .5s ease-in-out;
}

.product-item:hover{
  transform: scale(1.05);
  transition: .5s ease-in-out;
}

.product-item .actions-btn{
  background-color: #dfdfdf;
  border-radius: 0px 0px 20px 20px;
}

.slick-slide img {
  display: inline-block;
  border-radius: 20px;
}

.pro-img > a {
  display: block;
}

.actions-btn a i {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  color: #777;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease 0s;
  width: 40px;
  z-index: 2;
}

.actions-btn a i::after {
  background: #707070 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: -1;
}

.actions-btn a i:hover::after {
  transform: scale(1);
}

.actions-btn li:last-child a i {
  margin-right: 0;
}

.actions-btn a i:hover {
  color: #fff;
  border-color: #707070;
}

.product-title a h5 {
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  display: inline-block;
}

.product-title a h5 p {
  font-size: 16px !important;
  font-weight: 400;
}

.product-title span {
  color: #707070;
  margin-left: 10px;
}

.product-title a h5:hover,
.product-content .product-title a h5:hover {
  color: #707070;
}

.single-slide .padding30 {
  padding: 15px;
}

.actions-btn ul {
  display: inline-block;
  padding: 8px 0;
}

.featured-slider .actions-btn ul {
  opacity: 1;
  // z-index: -1;
  position: relative;
}

.featured-slider .actions-btn ul,
.single-slide .padding30,
.slick-current .single-slide .padding30 .actions-btn ul,
.slick-current .single-slide .padding30 {
  transition: all 0.5s ease 0s;
}

@media only screen and (min-width: 280px) and (max-width: 768px){
  .featured-slider {
    height: 355px !important;
  }
}

.featured-slider {
  height: 310px;
}

.featured-slider .actions-btn a {
  font-size: 16px;
  font-weight: 600;
  color: #2b2c2b;
}

.slick-current .single-slide .padding30 .actions-btn ul {
  // padding: 20px 0;
  opacity: 1;
  z-index: 1;
}

.single-slide .pro-img {
  padding: 15px 0;
}

.slick-current .single-slide .padding30 .pro-img,
.slick-current .single-slide .padding30 {
  // padding: 0px !important;
}

/* featured product */
.featured-slider.single-products {
  margin-left: -30px;
  margin-right: -30px;
}

.pro-img {
  position: relative;
}

.riding-one .pro-img::after,
.link-icon a {
  opacity: 0;
}


/* next prev */
.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 2.3em;
  transition: 0.4s;
}

.next.slick-arrow {
  right: -18px;
}

.prev.slick-arrow {
  left: -18px;
}

/* slick-arrow */
i.slick-arrow {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #adadad;
  border-radius: 3px;
  color: #adadad;
  font-size: 25px;
  height: 47px;
  line-height: 47px;
  width: 47px;
}

i.slick-arrow:hover {
  background: #707070 none repeat scroll 0 0;
  border-color: #fff;
  border-radius: 4px;
  color: #fff;
}

.slick-track {
    height: 100%;
}
.slick-list {
    height: 100%;
}



.pro-details-f.footer-contact::after {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}

.buy-online-label{
  font-size: 15px;
  margin-right: 5px;
  color: #3f51b5;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.buyNow:hover{
  transform: scale(1.05);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-wrap .product-content h3{
  font-weight: 500;
}

.main-menu nav ul li:hover a {
    color: #ffffff;
}

.sidebar-widget{
  padding: 15px;
  border: 1px solid #9d9d9d;
} 

.sidebar-widget h4.pro-sidebar-title{
  font-size: 20px;
  font-weight: 600;
  color: #434343;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark{
  background-color: #434343;
  border-color: #434343;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after{
  font-family: FontAwesome;
   content: "\f00c";
    font-size: 10px;
    color: #fff;
}

.sidebar-widget .sidebar-widget-list ul li button {
  font-size: 20px;
    font-family: $alg;
    font-weight: 600;
    color: #434343;
    text-transform: none;
}

.sidebar-style h2{
  font-family: $teko;
  font-size: 36px;
  font-weight: 600;
  color: #434343;
}